<template>
  <div class="ly_index">
    <div class="ly_head">
      <video
        ref="video"
        webkit-playsinline="true"
        playsinline="true"
        v-bind:volume="volume ? 1 : 0"
        loop
        autoplay
        control
        muted="trun"
        style="min-width:100%;height:100vh;object-fit: fill"
      >
        <source :src="src" />
      </video>
      <div
        class="sound"
        @click="volume = !volume"
        :class="[volume ? '' : 'voice']"
      >
        <img src="@/assets/image/all/volume.png" alt="" />
      </div>
      <ul class="ly_head_info" :class="[top != 0 ? 'is_show' : '']">
        <li v-for="(item, idx) in infoList" :key="idx">
          <div class="li_img">
            <img :src="infoImg[idx]" alt="" />
          </div>
          <div class="li_nav">
            <h2>{{ item.value }}</h2>
            <h4>{{ item.key }}</h4>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="ly_img"
      style="display:none"
      v-if="titList.length != 0"
      :style="{
        background: `url(${bgImg[5]}) 100%/100% no-repeat`
      }"
    >
      <h2>{{ titList[4].key }}</h2>
      <p>{{ titList[4].value }}</p>
    </div>
    <div class="ly_nav" v-if="titList.length != 0">
      <div class="all_title">
        <h2>
          {{ titList[0].key.slice(0, 2) }}<img :src="titImg[0]" />{{
            titList[0].key.slice(2, 4)
          }}
        </h2>
        <p>
          {{ titList[0].value }}
        </p>
      </div>
      <ul
        class="ly_nav_qijue"
        :style="{
          background: `url(${bgImg[4]})  no-repeat`
        }"
      >
        <li v-for="(item, idx) in qijueList" :key="idx">
          <a
            :href="'#/ly_introduce?currentIdx=' + (idx + 1)"
            @click="toLink(98)"
          >
            <div class="li_box zz_all_img">
              <img :src="item.icon_image" alt="" />
            </div>
            <p>{{ item.name }}</p>
          </a>
        </li>
      </ul>
      <div class="all_title">
        <h2>
          {{ titList[1].key.slice(0, 2) }}<img :src="titImg[1]" />{{
            titList[1].key.slice(2, 4)
          }}
        </h2>
        <p>
          {{ titList[1].value }}
        </p>
      </div>
      <div class="ly_nav_lxfw">
        <div class="lxfw_zxyd">
          <a href="#/ly_booking" @click="toLink(96)">
            <div class="lxfw_zxyd_mask"></div>
            <img :src="fwData.fw_img[0]" alt="" class="img" />
            <div class="lxfw_zxyd_content">
              <img :src="fwData.fw_img[1]" alt="" />
              <p style="font-size: 1.8vw;">{{ fwData.fw_zx }}</p>
              <p>
                {{ fwData.fw_text }}
              </p>
            </div>
          </a>
        </div>
        <ul class="lxfw_list">
          <li v-for="(item, idx) in lxfwList" :key="idx" class="lxfw_box">
            <a :href="'#/ly_give?currentIdx=' + idx" @click="toLink(100)">
              <div class="lxfw_box_mask"></div>
              <img :src="item.icon_image" alt="" class="img" />
              <div class="lxfw_box_content">
                <img :src="fwData.fw_list[idx].value" alt="" />
                <p style="font-size: 1.8vw;">{{ item.name }}</p>
                <p>
                  {{ fwData.fw_list[idx].key }}
                </p>
              </div>
            </a>
          </li>
        </ul>
        <div class="lxfw_ad">
          <img :src="bgImg[0]" alt="" />
        </div>
      </div>
      <div class="all_title">
        <h2>
          {{ titList[2].key.slice(0, 2) }}<img :src="titImg[2]" />{{
            titList[2].key.slice(2, 4)
          }}
        </h2>
        <p>
          {{ titList[2].value }}
        </p>
      </div>
      <div class="ly_nav_xwzx">
        <div class="xwzx_img">
          <el-carousel
            indicator-position="outside"
            height="22.5vw"
            class="xwzx_lbt"
          >
            <el-carousel-item v-for="(item, idx) in newList" :key="idx">
              <img
                :src="item.icon_image"
                alt=""
                style="width:100%;height:100%"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <ul class="xwzx_nav">
          <ul class="xwzx_nav_type">
            <li
              v-for="(item, idx) in newList"
              :key="idx"
              :style="{ color: active == idx ? '#fe8300' : '' }"
              @click="active = idx"
            >
              {{ item.name }}
            </li>
            <a :href="'#/ly_news'" class="more" @click="toLink(99)"
              >查看更多>></a
            >
          </ul>
          <ul class="xwzx_nav_list" v-if="newList.length != 0">
            <li v-for="(item, idx) in newList[active].news" :key="idx">
              <a
                :href="
                  '#/ly_news?currentIdx=' +
                    (active + 1) +
                    '&currentId=' +
                    item.id
                "
                @click="toLink(99)"
                ><h2 class="ellipsis">{{ item.title }}</h2>
                <p>{{ item.create_time }}</p>
              </a>
            </li>
          </ul>
        </ul>
      </div>
      <div class="all_title">
        <h2>
          {{ titList[3].key.slice(0, 3) }}<img :src="titImg[3]" />{{
            titList[3].key.slice(3, 6)
          }}
        </h2>
        <p>
          {{ titList[3].value }}
        </p>
      </div>
      <ul class="ly_nav_gxq">
        <li
          v-for="(item, idx) in xqList"
          :key="idx"
          :style="{ backgroundColor: xqList2[idx].key }"
        >
          <img src="@/assets/image/all/gxq_bg.png" alt="" class="img" />
          <div class="li_content">
            <div class="icon_box">
              <img :src="xqList2[idx].value" alt="" />
            </div>
            <h2>{{ item.key }}</h2>
            <p>{{ item.value }}</p>
          </div>
        </li>
      </ul>
      <div
        class="ly_dance"
        :style="{
          background: `url(${bgImg[1]})  fixed no-repeat center bottom`
        }"
      ></div>
      <div
        class="ly_dance2"
        :style="{
          background: `url(${bgImg[2]})  fixed no-repeat center bottom`
        }"
      ></div>
      <div
        class="ly_flower"
        :style="{
          background: `url(${bgImg[3]})  fixed no-repeat center bottom`
        }"
      ></div>
    </div>
    <div class="ly_footer">
      <ul class="ly_footer_list">
        <li v-for="(item, idx) in footerList" :key="idx">
          <div class="li_img">
            <img :src="item.value" alt="" />
          </div>
          <p>{{ item.key }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  getPageMsg,
  getXwData,
  getCaseTypes,
  getProductTypes,
  getBasicMsg
} from '@/api/api'
export default {
  data () {
    return {
      src: require('@/assets/video/huanglong.mp4'),
      bgImg: [],
      titList: [],
      titImg: [],
      volume: 0,
      infoList: [],
      infoImg: [],
      qijueList: [],
      lxfwList: [],
      fwData: {},
      newList: [],
      active: 0,
      xqList: [],
      xqList2: [],
      footerList: [],
      top: 0
    }
  },
  watch: {
    $route (to, from) {
      if (to.name == 'ly_index') {
        this.$refs.video.play()
      }
    }
  },
  computed: {},
  methods: {
    getData () {
      let that = this
      that.handleScroll()
      getBasicMsg(that.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.bgImg = res.data.data.basic.bgImg
              that.infoList = res.data.data.basic.headList
              that.infoImg = res.data.data.basic.headImg
              that.titList = res.data.data.标题配置.titleList
              that.titImg = res.data.data.标题配置.titleImg
              that.xqList = res.data.data.感兴趣.gxqList
              that.xqList2 = res.data.data.感兴趣.gxqList2
              that.footerList = res.data.data.底部.footList
              that.fwData = res.data.data.服务
            }
          })
          getXwData(project).then(res => {
            if (res.data.code == 1) {
              this.newList = res.data.data
            }
          })
          getProductTypes(project).then(res => {
            if (res.data.code == 1) {
              that.qijueList = res.data.data
            }
          })
          getCaseTypes(project).then(res => {
            if (res.data.code == 1) {
              that.lxfwList = res.data.data
            }
          })
        }
      })
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    },
    handleScroll () {
      window.addEventListener('scroll', () => {
        this.top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.ly_index {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  background-color: #fdfdfd;
  font-size: 14px;
  color: #666;
  .img {
    width: 100%;
    height: 100%;
  }
  .all_title {
    padding-top: 5vw;
    padding-bottom: 2.8vw;
    position: relative;
    h2 {
      font-size: 2vw;
      text-align: center;
      margin: 0;
      height: 4vw;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.25vw;
      color: #222;
      img {
        margin: 0 0.8vw;
      }
    }
    p {
      font-size: 1vw;
      text-align: center;
      margin-top: 1vw;
    }
  }
  .ly_head {
    width: 100%;
    height: auto;
    position: relative;
    .sound {
      position: absolute;
      width: 3vw;
      height: 3vw;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 2%;
      bottom: 3%;
      z-index: 9;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      img {
        width: 2vw;
        transition: all 0.3s linear;
      }
    }
    .voice img {
      width: 2.5vw;
    }
    .voice::before {
      content: '';
      position: absolute;
      left: 10%;
      top: 50%;
      width: 80%;
      height: 1px;
      background-color: #fff;
      transform: rotate(-45deg);
    }
    .ly_head_info {
      width: 80%;
      height: 7vw;
      background-color: #fff;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: -3.5vw;
      z-index: 10;
      box-shadow: 0 0 1vw rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: 0.8s all linear;
      li {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        .li_img {
          border: 1px solid #e3e3e3;
          width: 3.5vw;
          height: 3.5vw;
          line-height: 3.5vw;
          text-align: center;
          border-radius: 50%;
          margin-right: 1vw;
        }
        .li_nav h2 {
          font-size: 1.2vw;
          color: #333;
          margin-bottom: 0.78125vw;
          position: relative;
          box-sizing: border-box;
          transition: all 0.3s linear;
        }
      }
      li:nth-child(3) {
        .li_img {
          border: none;
          background: linear-gradient(to right, #fe8300, #fcbd00);
        }
        .li_nav h2 {
          color: #fe8300;
        }
        .li_nav h2::before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 1.65vw;
          height: 0.7vw;
          background: url(~@/assets/image/gif/zxyd.gif) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .is_show {
      opacity: 1;
    }
  }
  .ly_img {
    width: 100%;
    height: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    line-height: 1.5;
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .ly_nav {
    padding-top: 3.5vw;
    position: relative;
    width: calc(100% - 20vw);
    margin: 0 auto;
    .ly_dance,
    .ly_dance2,
    .ly_flower {
      position: absolute;
      width: 100%;
      transition: all 1s linear;
    }
    .ly_dance {
      top: 10%;
      height: 35vw;
      background-position: 18% 90% !important;
    }
    .ly_dance2 {
      height: 62vw;
      left: 0;
      bottom: 2%;
      background-position: 20% 90% !important;
    }
    .ly_flower {
      top: 61%;
      height: 64vw;
      bottom: 0%;
      background-position: 84% 35% !important;
    }
    .ly_nav_qijue {
      width: 100%;
      height: 16.66667vw;
      padding: 0 3vw;
      background-size: 100% auto !important;
      background-position: bottom !important;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -3vw;
      position: relative;
      z-index: 10;
      li {
        width: calc(14.28% - 1.2vw);
        height: 13.5vw;
        margin: 0 0.6vw;
        position: relative;
        font-size: 1.25vw;
        color: #fff;
        border-radius: 0.4vw;
        background-color: rgba(181, 181, 181, 0.3);
        .li_box {
          width: 10vw;
          height: 13.5vw;
          margin-top: 0.4vw;
          margin-left: -0.4vw;
          border-radius: 0.4vw;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          width: 1vw;
          font-size: 1vw;
          max-width: 7.8vw;
          padding: 0 0.4vw;
          position: absolute;
          line-height: 1.5;
          left: 0.8vw;
          bottom: 0.4vw;
          border-radius: 0.2vw;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
    .ly_nav_lxfw {
      display: flex;
      flex-wrap: wrap;
      .lxfw_zxyd {
        width: 33%;
        margin-right: 1vw;
        height: 41vw;
        z-index: 2;
        position: relative;
        cursor: pointer;
        .lxfw_zxyd_mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0);
          z-index: 3;
          transition: all 0.3s linear;
        }
        .lxfw_zxyd_content {
          position: absolute;
          width: calc(100% - 4vw);
          bottom: 5vw;
          left: 2vw;
          z-index: 4;
          color: #fff;
          p {
            margin-top: 1vw;
            line-height: 1.5;
          }
        }
      }
      .lxfw_zxyd:hover .lxfw_zxyd_mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .lxfw_list {
        width: calc(67% - 0.5vw);
        height: 41vw;
        z-index: 2;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-right: -1vw;
        li {
          width: calc(50% - 1vw);
          height: 20vw;
          margin: 0 0.5vw;
          margin-bottom: 1vw;
          position: relative;
          cursor: pointer;
          transition: all 0.3s linear;
          .lxfw_box_mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0);
            z-index: 3;
            transition: all 0.3s linear;
          }
          .lxfw_box_content {
            text-align: center;
            position: absolute;
            width: calc(100% - 4vw);
            bottom: 5vw;
            left: 2vw;
            z-index: 4;
            color: #fff;
            p {
              line-height: 1.5;
            }
          }
        }
        li:hover .lxfw_box_mask {
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      .lxfw_ad {
        width: 100%;
        min-height: 15vw;
        margin-top: 1.5vw;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .ly_nav_xwzx {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 10;
      .xwzx_img {
        width: 50%;
        height: 22.5vw;
        /deep/.el-carousel__indicators--outside {
          position: absolute !important;
          bottom: 0.5vw;
          margin: 0 auto;
          transform: translate(-50%, 0);
        }
      }
      .xwzx_nav {
        width: calc(50% - 4vw);
        padding: 2vw;
        height: 18.5vw;
        background-color: rgba(212, 212, 212, 0.3);
        font-size: 1vw;
        overflow: hidden;
        .xwzx_nav_type {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1vw;
          li {
            cursor: pointer;
          }
          .more {
            font-size: 0.8vw;
            margin-top: 0.2vw;
          }
          li:hover {
            color: #fe8300;
          }
        }
        .xwzx_nav_list {
          color: #222;
          li {
            font-size: 0.9vw;
            padding-left: 0.9vw;
            position: relative;
            cursor: pointer;
            a {
              height: 2vw;
              display: flex;
              align-items: center;
              justify-content: space-between;
              h2 {
                width: 80%;
              }
            }
          }
          li::before {
            content: '';
            width: 0.5vw;
            height: 0.5vw;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            background: url(~@/assets/image/all/point.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    .ly_nav_gxq {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 12vw;
      color: #fff;
      padding-bottom: 5vw;
      position: relative;
      z-index: 10;
      li {
        width: 24%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        cursor: pointer;
        .img {
          position: absolute;
          top: 0;
          left: 0;
          height: auto;
        }
        .li_content {
          .icon_box {
            width: 3.5vw;
            height: 3.5vw;
            border-radius: 50%;
            border: 1px solid white;
            margin: 0 auto 1vw;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              transition: all 0.3s linear;
              width: 32px;
            }
          }
          h2 {
            font-size: 1.25vw;
          }
          p {
            margin-top: 1vw;
          }
        }
      }
      li:hover .icon_box img {
        animation: rotate 0.3s linear;
      }
    }
  }
  .ly_footer {
    padding: 4vw 0 3vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #1e1e1e;
    color: #999;
    .ly_footer_list {
      display: flex;
      justify-content: space-between;
      width: 68%;
      margin: 0 auto;
      li {
        text-align: center;
        .li_img {
          width: 5vw;
          height: 5vw;
          line-height: 5vw;
          margin: 0 auto;
          text-align: center;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }
        p {
          margin-top: 1vw;
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(270deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
</style>
